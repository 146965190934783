$checkbox-input-height:          16px;
$checkbox-input-width:           16px;
$checkbox-border-radius:          5px;
$checkbox-bg-color:              $white;
$checkbox-border-color:          $gray3;
$checkbox-border-color-hover:    $primary;
$checkbox-border-color-focus:    $primary-light;
$checkbox-border-color-focus-checked: 0 0 0 3px rgba($primary-light, 0.25);
$checkbox-border-color-checked:  $gray3;
$checkbox-bg-checked:            $white;
$checkbox-bg-disabled:           $gray5;
$checkbox-border-color-disabled: $gray4;
$checkbox-font-color-disabled:   $gray2;
$checkbox-tick-color:            $white;

$switch-height:  20px;
$switch-offset: 2px;
$switch-time: 0.25s;
$switch-bg: $gray4;
$switch-slider-color: $white;
$switch-bg-alt: $white;
$switch-slider-color-alt: $white;
$switch-bg-no-checked: $gray4;
$switch-bg-checked: $primary;
$switch-border-color: $gray3;
$switch-border-radius: calc(#{$switch-height}/2);
$switch-bg-disabled: $gray3;
$switch-slider-disabled: $gray4;
$switch-slider-size:  calc(#{$switch-height} - #{$switch-offset}*2);
$switch-width: calc(#{$switch-height}*2 - #{$switch-offset}*2);

@import 'node_modules/ns-checkbox/src/scss/component';

.ns-checkbox {
  .ns-checkbox__input.is-checked {
    .ns-checkbox__original:focus ~ .ns-checkbox__inner {
      box-shadow: none;
    }

    .ns-checkbox__inner {
      &:after {
        content: '';
        transform: none;
        top: 2px;
        bottom: 2px;
        left: 2px;
        right: 2px;
        min-width: 10px;
        min-height: 10px;
        max-width: 10px;
        max-height: 10px;
        border-radius: 2px;
        height: auto;
        background-color: $primary;
        border: none;
      }
    }
  }

  .ns-checkbox__inner {
    top: 2px;
  }
}

.ns-checkbox.small-text {
  .ns-checkbox__inner {
    top: 4px;
  }

  & + span {
    color: $black;
    font-size: $font-size-small;
  }
}

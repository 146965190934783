.ns-session {
  @include flex-middle;

  i.ns-icon-angle-down {
    color: $gray2;
  }

  .ns-bubble-session {
    .bubble {
      background-color: $white;
      min-width: 260px;
      padding: 0;
      margin-left: -#{d2($space)};
      border: 1px solid $gray4;
      filter: none;
    }

    .trigger:hover {
      .ns-session__user-icon {
        background-color: $primary-light;
        color: $primary;
      }

      i.ns-icon-angle-down,
      span {
        color: $gray2 !important;
      }
    }
  }
}

.ns-session__nav {
  color: $black;
  font-size: $font-size;
  ul {
    display: flex;
    flex-direction: column!important;
  }

  li {
    min-height: x1-2($space);
    padding: d4($space) d2($space);
    margin-left: d4($space)!important;
    i,span{
      color: $primary !important;
    }
    &:not(.ns-session__nav--city):hover {
      color: $primary;
      cursor: pointer;
    }

    &.ns-session__nav--city i:hover {
      color: $black !important;
      cursor: pointer;
    }
    &:hover {
      i,span {
        color: $primary!important;
      }
    }
  }
}

.ns-session__nav--sidebar {
  color: $black;
  //font-size: $font-size;
  font-size: $font-size-extra;
  width: 100%;

  img {
    width: auto;
    height: 60px;
  }

  ul {

    li{
      min-height: x1-2($space);
      //padding: d4($space) d2($space) d1-2($space) d2($space);
      padding: 0 !important;
    }
    img{
      height: 24px
    }
  }
}

.ns-session__nav--login {
  @extend .ns-session__nav--sidebar;
  padding: d2($space) d2($space) d2($space) x1-2($space);

  .logo-app {
    width: x5($space);
  }

  & + .ns-bubble {
    margin-left: x1-2($space);
  }

  ul {
    @include flex-column;
    flex-direction: column-reverse;
  }

  ul.brand_menu {
    li {
      padding: 0.5rem !important;
      &:hover {
        background-color: $gray5;
        border-radius: $border-radius;
      }
    }
    li.phone{
      background-color: $gray5;
      display: inline-block;
      //padding: 0.5rem 0.75rem 0.5rem 0.75rem;
      padding: 0rem !important;
      border-radius: 8px;
      line-height: initial;
      //border: 3px solid transparent;

      &:hover {
        .button-phone {
          &:hover {
            box-shadow: 0px 2px 2px 0px transparentize($black, .98),
                        0px 2px 2px 0px transparentize($black, .91);
          }
        }
      }
    }
  }

  li {
    @include flex-middle;
    padding: d4($space) d2($space) d1-2($space) d2($space) !important;
    cursor: pointer;

    a {
      @include flex-middle;
    }

    i, span {
      //color: $gray2;
      color: $primary;
    }

    &:hover {
      i, span {
        color: $black;
      }
    }
  }

  @include media(md) {
    ul {
      @include flex-middle;
      padding-top: 0 !important;
      flex-direction: row;
    }

    li {
      //padding: d3($space) !important;
    }

    li + li {
      margin-left: d2($space);
    }
  }
}

.ns-session__user-icon {
  background-color: $primary-light;
  color: $primary!important;
  padding: d3($space);
  //font-size: d1-2($space);
  font-size: $font-size;
  border-radius: 50%;

  &.large {
    @include flex-middle;
    justify-content: center;
    font-size: x1-2($space);
    width: x3($space);
    height: x3($space);

    i {
      font-size: x1-2($space);
    }
  }
}

@mixin general-conditions-transport {
  li {
    list-style-type: none;
  }

  p {
    text-align: justify;
    text-justify: inter-word;
  }
    
    div > *{
      display: block;
      margin-left: 30px;
     
  } 

  .margin-right-transport{ 
    margin-right: 60px;
    margin-left: 0px
  }
}
.hidden-vz {
  display: none !important;
}

.font-weight-normal {
  font-weight: 400;
}

.link,
a.link {
  color: $link-color;
  &:hover {
    color: $link-color-hover;
  }
}

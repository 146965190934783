//------------------------------ FONT SETTINGS -------------------------------//

// Primary font
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');
$font-family: "Nunito", sans-serif;

// Secondary font
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700&display=swap');
$font-family-secondary: "Raleway", serif;


// Breakpoints
$root-sizes: (
  xs: 14,
  sm: 14,
  md: 16,
  lg: 16,
) !default;


// Line height
$line-height: 1.5;


// Factor font scale
$scale-xs: 1.15;
$scale-sm: 1.2;
$scale:    1.2;
$scale-lg: 1.2;


// Modular font scale
$scale-level: (
  alpha:     5,
  beta:      4,
  gamma:     3,
  delta:     2,
  epsilon:   1,
  zeta:      0,  // Tamaño Base
  eta:      -1,
  theta:    -2,
  iota:     -3
) !default;

// Fonts-weight
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

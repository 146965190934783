.text-size-title{
  font-size: 20px;
}

.text-color-gray {
  font-size: 14px;
  color: #9A9BA0;
}

.pb-5 {
  padding-bottom: 5px;
}

.text-color-alert {
  font-size: 14px;
  color: #FF4B55;
}

.text-color-black {
  font-size: 14px;
  color: #0e2235;
}


.column-name {
  min-width: fit-content;
  padding-right: 5px;
  text-align: center;
}

.column-details { 
  justify-content: flex-start; /* Alineamos los elementos al inicio del contenedor */
  flex-grow: 2;
}

.row-element {
  display: flex;
  flex-direction: row;
}

.hover-btn:hover {
  opacity: 0.4;
}

.move-span {
  transform: translateY(50%);
}

.panel-list-qr-tne__content__icon{
  align-items: start !important;
}

.iconContent{
  align-self: center;
}
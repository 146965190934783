.recharge-home__cities {
  background-color: $primary !important;
  // background: url('assets/images/page/white-curve.svg') no-repeat;
  // background-size: 100vw;
  // background-position: center bottom 45px;

  & .ns-layout__content__compacted--home > div {
    margin-bottom: x1-2($space);
  }
}

.recharge-home__recharges {
  @include flex-middle;
  justify-content: center;

  .recharges-home__recharges__content {
    @include flex-column;
    justify-content: center;
    margin-top: x1-2($space);
  }

  .recharges-home__recharges__container {
    @include flex-column;
    flex-direction: column-reverse;
    align-items: center;

    img {
      width: 18rem;
    }
  }
}

.recharge__img {
  display: none;
}

.recharge__payload-animation {
  @include flex-middle;
  @include size(x4($space));
  background: url('assets/images/page/loading.svg');
  background-size: x4($space);
  margin: 0 auto;
  animation: imgRotate 1.5s infinite;

  // No mostrado para Vizur
  .paycard {
    display: none;
  }
}

.copy-and-pay-payment-platform {
  min-height: 370px;
}

.iframe--home-video {
  width: 100%;
  @media screen and (min-width: $sm) {
    height: 300px;
  }
  @media screen and (min-width: $md) {
    width: 960px;
    height: 402px;
    margin-bottom: x4($space);
  }
}

.recharge-select-panel {
  @media screen and (min-width: $sm) {
    min-height: 30rem;
  }
}

@media screen and (min-width: $sm) {
  .recharge-home__recharges .recharges-home__recharges__container {
    img {
      width: 20rem;
    }
  }

  .recharge__img {
    min-height: 205px;
    .recharge__img--qr {
      height: 205px;
    }

    img:not(.recharge__img--qr) {
      width: 90%;
      padding: 0;
    }
  }

  .recharge__payload-animation {
    .payment-line {
      height: 3px;
    }
  }

  .copy-and-pay-payment-platform {
    min-height: 180px;
  }
}
@include media(md) {
  .recharge-home__cities {
    background: url('assets/images/page/white-curve.svg') no-repeat;
    background-size: 100vw;
    background-position: center bottom 45px;

    & .ns-layout__content__compacted--home > div {
      margin-bottom: 12rem;
    }
  }

  .recharge-home__recharges .recharges-home__recharges__content {
    margin-top: 0;
  }

  .recharge-home__recharges {
    background-color: $background-color;
    margin-top: -#{x2($space)};
  }

  .recharge-home__recharges .recharges-home__recharges__container {
    @include flex;
    flex-direction: row;

    img {
      min-width: 25rem;
      width: 25rem;
      margin: 0;
    }
  }
}

//----------------------------- BUTTON VARIABLES -----------------------------//
$button-font-color:                         $white;
$button-font-color-secondary:               $gray1;
$button-font-size:                          $font-size;
$button-height:                             x1-2($space);
$button-padding-x:                          d2($space);
$button-border-color:                       transparent;
$button-border-color-hover:                 transparent;
$button-border-color-secondary:             $gray4;
$button-border-color-secondary-hover:       $gray4;
$button-border-radius:            24px;

$button-sm-font-size:          $font-size-x-small;
$button-sm-height:             $space;
$button-sm-padding-x:          d2($space);

$button-lg-font-size:          $font-size-small;
$button-lg-height:             x2($space);
$button-lg-padding-x:          d1-2($space);

$button-transparent-bg:           transparent;
$button-transparent-border-color: rgba($white, 0);

$button-disabled-bg:              $gray5;
$button-disabled-font-color:      $gray3;
$button-disabled-border-color:    $gray4;


//----------------------------- BUTTON COMPONENT -----------------------------//

// Create buttons from button-mixin
//
// @include button(bg-color, bg-color-hover, font-color, font-color-hover, border-color, border-color-hover);

.ns-button {
  @include button(transparent, $primary, $button-font-color, $white,
                  $button-border-color, $button-border-color-hover);
}

.ns-button--primary {
  @include button($primary, tint($primary, 25%), $button-font-color, $button-font-color,
                  $button-border-color, $button-border-color-hover);

  font-family: $font-family-secondary;

  &:active,
  &:focus {
    background-color: $primary-dark;
  }
  &:focus {
    box-shadow: 0 0 3px $primary-dark;
  }

  &.ns-button--disabled {
    &,
    &:hover {
      background-color: $button-disabled-bg !important;
      color: $button-disabled-font-color !important;
      border-color: $button-disabled-border-color !important;
      font-weight: $regular;
      box-shadow: none;
    }
  }
}

.ns-button--secondary {
  @include button($white, $gray5, $button-font-color-secondary, $button-font-color-secondary,
                  $button-border-color-secondary, $button-border-color-secondary-hover);
  font-weight: $medium;
  font-family: $font-family-secondary;

  &:active,
  &:focus {
    background-color: $gray4;
    border-color: $gray3;
  }
  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(199, 207, 209, 0.4);
  }

  &.ns-button--disabled,
  &.ns-button--disabled:hover {
    background-color: $button-disabled-bg !important;
    color: $button-disabled-font-color !important;
    border-color: $button-disabled-border-color !important;
    font-weight: $regular;
    box-shadow: none;
  }
}

.ns-button--primary,
.ns-button--secondary {
  box-shadow: 0px 1px 2px 0px transparentize($black, .9);
}

.ns-button--ghost {
  @include button($button-transparent-bg, $gray5, $gray1, $gray1,
                  $button-transparent-border-color, $button-transparent-border-color);
  font-weight: $medium;

  &:active,
  &:focus {
    background-color: $gray4;
  }
  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(198, 208, 210, 0.4);
  }
}

.ns-button {
  height: $button-height;
  line-height: $button-height;
  padding: 0 $button-padding-x;
  font-size: $font-size-small;

  & + .ns-button {
    margin-left: d2($space);
  }
}

.ns-button--sm {
  height: $button-sm-height;
  line-height: $button-sm-height;
  padding: 0 $button-sm-padding-x;
  font-size: $button-sm-font-size;
}

.ns-button--lg {
  height: $button-lg-height;
  line-height: $button-lg-height;
  padding: 0 $button-lg-padding-x;
  font-size: $button-lg-font-size;
  font-family: $font-family-secondary;
  font-weight: $medium;
  letter-spacing: 0px;

  span {
    letter-spacing: 0;
    text-transform: uppercase;
  }
}

.ns-button--disabled,
.ns-button--disabled:hover,
.ns-button--disabled:active,
.ns-button--disabled:focus {
  background:   $button-disabled-bg !important;
  color:        $button-disabled-font-color !important;
  border-color: $button-disabled-border-color !important;
  cursor:       not-allowed;
}

.ns-button--icon {
  // padding-left: d3($space);
  
  & > div > span {
    @include flex-middle;
  }
}

.ns-button--icon > div > span,
.ns-button--icon > div,
a.ns-button--icon {
  i {
    font-size: $space;
    background-color: #e8edf3;
    border-radius: 8px;
    padding: 0.5rem;
  }
  i ~ span {
    margin-left: d3($space);
  }
  span ~ i {
    margin-left: d4($space);
  }
}

.ns-button.small-text {
  span {
    @include fontsize(eta);
    letter-spacing: 0;
    text-transform: uppercase;
  }

  div {
    max-height: d1-2($space);
  }
}

.ns-button--left {
  width: 100%;
  & > div {
    @include start;
  }
}

.button-phone {
  display: inline-block;
  padding: d3($space) d2($space) d3($space) d2($space);
  border-radius: $border-radius;
  //margin-top: -6.5%;
  line-height: initial;
  &:hover {
    box-shadow: 0px 2px 2px 0px transparentize($black, .98),
                0px 2px 2px 0px transparentize($black, .91);
  }
  i {
    //font-size: 1.2rem;
    font-size: 1.5rem;
  }
}
.contraste{
  background-color: black !important;
  color: white !important;
}
.contraste2{
  background-color: white  !important;
  color: black !important;
}
// .modalStyle{
//   display: flex;
//   box-sizing: border-box;
//   border-color: currentColor;
//   transition-property: all;
//   transition-timing-function: cubic-bezier(0.2,0,0,1);
//   color: #8393A3;
//   line-height: 1.5rem;
//   text-transform: uppercase;
//   font-weight: 700;
//   text-align: center;
//   font-size: 0.75rem;
//   padding-top: 12px;
//   padding-bottom: 12px;
//   border-style: solid;
//   border-left-width: 1px;
//   border-top-left-radius: 0rem;
//   border-bottom-left-radius: 0rem;
//   padding-right: 3rem;
//   border-bottom-right-radius: 0rem;
//   border-top-right-radius: 0rem;
//   letter-spacing: 1.8px !important;
//   border-bottom-width: 1px;
//   border-top-width: 1px;
//   border-right-width: 1px;
//   padding-left: 15px;
//   background-color: #fff !important;
// }
// .tamanoli{
 
//   margin: 10px !important;
// }
// .titulomodal{
//   font-size: 0.8rem !important;
// }
// .restablecer{
//   padding-left: 8rem;
//   background-color: #fff !important;
// }
// .tamanoicono{
//   height:20px !important;
// }
.miga-pan{
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  background-color: white;
  font-size: 0.8rem !important;
  padding-left: 2rem;
  margin-top: 1.5rem;
  height: 2rem;
  padding-top: 0.5rem;
}
.marginaccessibility{
  margin-left: 1rem;
}
.tablacss{
  color:#4d6f8f
}
.colorFondo{
  background-color: #E8EDF3;
}
.submenuusuario{
  color: #4d6f8f ;
}
a:focus{
  outline: none;
  border-color: #007bff;
  border-radius: 250px;
}

// Responsive ----------------------------------------------------------------//
@include media(xs) {
  a.ns-button {
    @include flex-center;
  }

  .ns-button--icon > div > span,
  .ns-button--icon > div,
  a.ns-button--icon {
    i {
      font-size: 1.5rem;
    }
  }
}
.button-phoneborder{
  border: solid !important;
  font-size: 19px !important;
}
.style-phone{
  font-weight: bold !important;
  font-size: 12px !important;
}
.style-phone-ico{
  align-items: end !important;
  padding-bottom: 4px !important;
}
.style-ico-phone{
  height: 20px !important
}
.style-texto-vz{
  font-size: 9px !important;
  text-align: end;
}
.style-size-phone{
  font-size: 18px !important;
}

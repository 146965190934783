//------------------------------ COLORS SETTINGS -----------------------------//

// Brand colors
$primary-dark: hsl(209,59,13);  //#0E2336
$primary: hsl(209,59,13);       //#0E2336
$primary-light: hsl(209,30,85); //#CDD9E4

$color-brand-map: (
  'primary': $primary,
  'primary-light': $primary-light,
  'primary-dark': $primary-dark
) !default;


// Gray scale
$black: hsl(209,59,13); //#0E2336
$gray1: hsl(209,30,28); //#32485D
$gray2: hsl(209,30,43); //#4D6F8F
$gray3: hsl(209,30,60); //#7A9AB8
$gray4: hsl(209,30,85); //#CDD9E4
$gray5: hsl(209,31,93); //#E8EDF3
$white: hsl(209,30,100);//#FFFFFF
$background: hsl(210,31,95);//#EEF2F6

$color-gray-map: (
  'black': $black,
  'gray1': $gray1,
  'gray2': $gray2,
  'gray3': $gray3,
  'gray4': $gray4,
  'gray5': $gray5,
  'white': $white,
  'background': $background
) !default;

// Servicios de Bus
$low-occupancy-bg: #C7E184;
$low-occupancy-border: #81A428;

$half-occupancy-bg: #F9E4CA;
$half-occupancy-border: #FCAA1F;

$high-occupancy-bg: #FFCCE2;
$high-occupancy-border: #FF026E;

// Notify colors
$info-dark: hsl(182,60,40);  //#1D7A7C
$info: hsl(182,60,60);       //#30CCCF
$info-light: hsl(182,60,85); //#C1F0F1

$error-dark: hsl(7,80,40);   //#900C04
$error: hsl(7,80,60);        //#FA3E32
$error-light: hsl(7,80,85);  //#FECBC8

$warning-dark: hsl(36,100,50); //#B68707
$warning: hsl(36,100,70);      //#F7C12E
$warning-light: hsl(36,100,90);//#FDECBF

$success-dark: hsl(150,60,35); //#415214
$success: hsl(150,50,55);      //#A7D040
$success-light: hsl(150,60,85);//#D9EBAD

$danger-dark: $error-dark;
$danger: $error;
$danger-light: $error-light;

// Secondary and terciary colors
$secondary: $black;
$terciary: $info;

// Customs
$accesible: #2C4CD8;

$color-notify-map: (
  'info-dark': $info-dark,
  'info': $info,
  'info-light': $info-light,
  'error-dark': $error-dark,
  'error': $error,
  'error-light': $error-light,
  'warning-dark': $warning-dark,
  'warning': $warning,
  'warning-light': $warning-light,
  'accesible': $accesible,
  'success-dark': $success-dark,
  'success': $success,
  'success-light': $success-light,
  'danger-dark': $danger-dark,
  'danger': $danger,
  'danger-light': $danger-light
) !default;


// Color gradient
$gradients-map: (
  'gradient-start': $primary,
  'gradient-end': $secondary
) !default;

$radio-input-height: 16px;
$radio-input-width: 16px;
$radio-input-border-radius: 50%;

$radio-bg-color: $white;
$radio-border-color: $gray3;
$radio-border-color-hover: $primary;
$radio-border-color-checked: $primary;
$radio-bg-checked: $primary;
$radio-border-color-disabled: $gray4;
$radio-bg-disabled: $gray5;
$radio-font-color-disabled: $gray2;
$radio-tick-color: $white;

$radio-icon-color: $gray3;
$radio-icon-color-checked: $primary;

$radio-color-height: $space;
$radio-color-width: $space*1.5;
$radio-color-checked-border: 2px solid $primary;
$radio-color-hover-border: 2px solid $secondary;
$radio-color-disabled-border: 1px solid $gray4;
$radio-color-disabled-bg: $gray5;

$radio-circle-size: d1-2($space);
$radio-circle-size-small: 10px;

@import 'node_modules/ns-radio/src/scss/component';

.ns-radio {
  @include flex;
  padding-left: 0;
  height: auto;

  .ns-radio__label {
    padding-top: 1px;
    padding-left: d3($space);

    span {
      white-space: normal;
    }
  }

  .ns-radio__input,
  .ns-radio__input.is-checked {
    .ns-radio__inner,
    .ns-radio__inner:hover {
      background-color: $white;
      border-color: $gray4;
    }
  }

  .ns-radio__input.is-checked .ns-radio__inner:after {
    width: $radio-circle-size-small;
    height: $radio-circle-size-small;
    background-color: $primary;
    border: 1px solid $gray4;
  }
}

.ns-radio-custom {
  width: 100%;

  label > div {
    padding: d2($space) d1-2($space);
    border: 1px solid $gray4;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  [type="radio"] {

    & + label {
      border-radius: 8px !important;

      .ns-radio-custom--circle {
        @include flex-center;
        align-items: center;
        width: $radio-circle-size;
        height: $radio-circle-size;
        background-color: $white;
        border: 1px solid $gray4;
        border-radius: 50%;
        margin-right: d3($space);
        pointer-events: none;
      }

      i {
        color: $gray3;
      }
    }

    &:checked + label {
      background-color: $info-light;

      .ns-radio-custom--circle:before {
        content: '';
        width: $radio-circle-size-small;
        height: $radio-circle-size-small;
        background-color: $info;
        border: 1px solid $gray4;
        border-radius: 50%;
      }

      i {
        color: $info;
      }
    }
  }

  &.ns-radio-custom label {
    z-index: 1 !important;
  }
}

.radio-custom-vfor {
  @include flex;
  @include column;

  .ns-radio-custom:first-of-type {
    margin-bottom: d4($space);
  }

  .ns-radio-custom:nth-of-type(2n) {
    margin-bottom: d4($space);
  }
}

@media (min-width: $sm) {
  .radio-custom-vfor {
    @include flex;
    @include flex-row;

    .ns-radio-custom:first-of-type {
      margin-bottom: 0;
      margin-right: d1-2($space);
    }

    .ns-radio-custom:nth-of-type(2n) {
      margin-bottom: 0;
      margin-right: d1-2($space);
    }
  }
}

$field-bg-color:                   $white;
$field-font-color:                 $black;
$field-font-size:                  $font-size;
$field-placeholder-color:          $gray2;
$field-border-color:               $gray4;
$field-border-color-hover:         $gray2;
$field-border-color-focus:         $black;
$field-border-color-active:        $black;
$field-box-shadow:                 0px 0px 3px 0px rgba(69, 79, 91, 0.2);
$field-bg-color-disabled:          $gray4;
$field-font-color-disabled:        $gray2;
$field-placeholder-color-disabled: $gray4;
$field-border-color-disabled:      $gray4;
$field-icon-color:                 $gray2;
$field-icon-color-disabled:        $gray4;
$field-color-info:                 $info;
$field-color-success:              $success-dark;
$field-color-warning:              $warning;
$field-color-danger:               $danger;
$field-border-radius:              d3($space);
$field-height:                     x1-2($space);
$field-padding:                    d2($space);
$field-padding-large:              d2($space);
$field-large-font-size: $font-size;
$field-large-height:    x2($space);
$field-bg-color-prepend:   $gray5;
$field-font-color-prepend: $gray2;
$field-transition: border 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

.vue__time-picker {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 100%;

  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  &.autoresize {
    width: auto!important;
    max-width: 100%;

    input {
      width: auto!important;
      max-width: 100%;
    }
  }

  input {
    display: inline-block;
    position: relative;
    width: 100%;
  }

  // Fix de opacidad del placeholder en Firefox
  input::-moz-placeholder {
    opacity: 1!important;
  }
  input[type=text]::-ms-clear {
    display: none!important;
  }
  input[type="time"]::-webkit-clear-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .clear-btn {
    display: none !important;
  }

  input.display-time {
    @include vendor(appearance, none);
    @include placeholder($field-placeholder-color);
    position: relative;
    display: block;
    width: 100%;
    padding: 0 $field-padding;
    padding-right: 2rem;
    background-image: none;
    background-color: $field-bg-color;
    border: 1px solid $field-border-color;
    border-radius: 4px;
    color: $field-font-color;
    font-size: $field-font-size;
    height: $field-height;
    line-height: $field-height;
    outline: 0;
    transition: $transition;

    // &:hover {
    //   border-color: $field-border-color-hover;
    // }

    &:focus,
    &:active {
      outline: 0;
      border-color: $field-border-color-focus;
      box-shadow: $field-box-shadow;
    }
    &.disabled, &:disabled {
      color: $field-font-color-disabled;
    }
  }

  // Icon clock
  &:after {
    position: absolute;
    content: $ns-icon-clock;
    top: 4px;
    right: 6px;
    font-family: ns-icon !important;
    font-size: 1.2rem;
    color: $gray3;
    pointer-events: none;
  }

  .clear-btn {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    top: 0;
    right: d6($space);
    bottom: 0;
    width: 1.3em;
    z-index: 3;
    font-size: $space;
    line-height: $field-height;
    vertical-align: middle;
    color: $field-icon-color;
    background: hsla(0, 0%, 100%, 0);
    text-align: center;
    font-style: normal;
    -webkit-margin-before: -.15em;
    -webkit-transition: color .2s;
    transition: color .2s;

    &:hover {
      color: $gray1;
      cursor: pointer;
    }

    &:active {
      outline: 0;
    }
  }

  .time-picker-overlay {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .dropdown {
    position: absolute;
    z-index: 99;
    top: calc(2.2em + 5px);
    left: 0;
    background: $white;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
    border-radius: d3($space);
    width: 100%;
    height: 10rem;
    font-weight: 400;

    .select-list {
      width: 100%;
      height: 10rem;
      overflow: hidden;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;

      &:active, &:focus {
        outline: 0;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      outline: 0;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0.00001px;
      flex: 1 1 0.00001px;
      overflow-x: hidden;
      overflow-y: auto;

      &.apms, &.minutes, &.seconds {
        border-left: 1px solid $white;
      }

      li {
        list-style: none;
        padding: d5($space) d1-2($space);
        margin: 0;
        color: $gray1;

        &:first-child {
          text-align: center;
          font-size: .875rem;
          color: $gray2;
        }

        &:not(.hint):not([disabled]) {
          &:focus, &:hover {
            background: tint($primary,70%);
            color: $primary;
            cursor: pointer;
            font-weight: bold;
          }
        }

        &:not([disabled]).active {
          background: $primary;
          color: $white;

          &:focus, &:hover {
            background: $primary-dark;
            color: $white;
          }
        }

        &[disabled] {
          background: $gray5;
          color: $gray2;
          cursor: not-allowed;

          &:hover {
            background: $gray5;
            color: $gray2;
            cursor: not-allowed;
          }
        }
      }
    }

    .hint {
      color: #a5a5a5;
      cursor: default;
      font-size: .8em;
    }
  }
}

.ns-field.focus .vue__time-picker input.display-time {
  border-color: $primary;
  box-shadow: $input-box-shadow-focus;
}

// Mostrar el dropdown por arriba en determinadas resoluciones
// para evitar que se vea cortado por el footer del sidepanel

@media (max-height: 57rem) {
  .vue__time-picker.dropdown-up {
    .dropdown {
      top: inherit;
      bottom: calc(2.2em + 5px);
    }
  }
}
